<template>
  <div class="content">
    <div class="toptitle">
      <span class="size36">企业责任</span>
      <p>Responsibility</p>
    </div>
    <div class="fix" @click="btn()">关于我们 &ensp;> &ensp;社会责任</div>
    <div class="title1">弘扬正气 好人好事不断</div>
    <div class="box"></div>
    <div class="title2">
      2021期间，公司共涌现各类好人好事1189起，为乘客挽回经济损失约43万元。其中被省、市级以上媒体报道的14起，收到乘客锦旗1面，收到乘客表扬信或表扬电话14起。
    </div>
    <img src="../../../static/images/about/4.png" alt="">
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  methods: {
    btn() {
      this.$router.push({ name: "pcsocial" });
    },
  },
};
</script>

<style lang="less" scoped>
.content {
  width: 100%;
  font-weight: 400;
  color: #2d2d39;
  font-size: 14px;
  .toptitle {
    box-sizing: border-box;
    padding: 6% 0 0 4%;
    .size36 {
      font-size: 36px;
    }
    p {
      margin-left: 1px;
      font-size: 16px;
    }
  }
  .fix {
    cursor: pointer;
    width: 100%;
    height: 43px;
    line-height: 43px;
    margin-top: 10px;
    padding-left: 4%;
    box-sizing: border-box;
    background-color: #f2f2f2;
  }
  .title1 {
    width: 61.8%;
    height: 24px;
    line-height: 24px;
    font-size: 24px;
    margin: 45px 0 0 4%;
  }
  .box {
    width: 61.8%;
    height: 1px;
    background-color: #e9e9e9;
    margin: 15px 0 0 4%;

  }
  .title2 {
    line-height: 25px;
    width: 61.8%;
    margin: 35px 0 0 4%;
  }
  img {
    width: 61.8%;
    margin: 30px 0 0 4%;
  }
}
</style>